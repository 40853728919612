import { PageTemplate } from './PageTemplate'
import { WFMatchingButtons } from '../components/Workflow/WfActionsComponent';
import { CandidateToDoComponent } from '../components/Candidate/CandidateToDoComponent'

const ShowCandidateToDoPage = () => {
    return (
        <PageTemplate
            type="candidate" headerPage="todopage" headerElement="header"
            leftPaneComponent={null} mainComponent={CandidateToDoComponent()} buttonComponent={WFMatchingButtons()} >
        </PageTemplate>
    );
}
export { ShowCandidateToDoPage }