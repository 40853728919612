import { Suspense, Show, For, createSignal, createMemo, createResource, splitProps, ErrorBoundary } from "solid-js";
import { Button, Stack, Card, Alert, Divider, } from "@suid/material";
import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { customerTodoMenu } from "../../../utils/menuUtil"
import { AvatarWithWfButtons, showWfCandidateToDoInterviewsItems, showWfCandidateToDoTerminateContractItems, showWfCandidateToDoPendingInterviewsItems, showWfCandidateToDoProposalItems, showWfCandidateToDoContractItems, showWfCandidateRecommendationItems } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList' 
import { getDummyAvatar } from '../../../utils/getdummyavatar'
import { getText, GetTextUI } from '../SmallComponents/Translate'
import { ShowErrorUI } from '../SmallComponents/SmallShowError'
import { formatDateByCountryLong, formatDateByCountry, formatDateYearMonthByCountry, formatTimeByCountry } from "../../../utils/datetimeflag"

import { useNavigate } from "@solidjs/router";

const apiUrl = import.meta.env.VITE_APP_API_URL;

const countrCode = "DK"

type CandidateToDoDataElementsType = {
    src: string;
    avatarId: string;
    avatarName: string;
    jobCustomer: string;
    candidateId: string;
    interviewId: string;
    proposalId: string;
    contractId: string;
    jobRoleId: string;
    jobId: string;
    jobName: string;
    jobCountry: string;
    jobCity: string;
    role: string;
    rateFlat: string;
    rateOnsite: string;
    rateRemote: string;
    currency: string;
    jobStartDate: string;
    jobEndDate: string;
    onSiteText: string;
    workOnsite: string;
    workRemote: string;
    interviewDate: string;
    interviewOnSite: string;
    interviewType: string;
    wfStatus: string;
}[] | null;

type CandidateToDoFetchType = {
    interviewsAccepted: CandidateToDoDataElementsType;
    interviewsNotAccepted: CandidateToDoDataElementsType;
    proposals: CandidateToDoDataElementsType;
    contracts: CandidateToDoDataElementsType;
    terminations: CandidateToDoDataElementsType;
    feedbacksAskFor: CandidateToDoDataElementsType;
    status: string;
    redirect: string;
};

const CandidateToDoComponent = () => {
    
    const [userId, setUserId] = createSignal<string | null>("1");

    const fetchToDoes = async (id: string | null): Promise<CandidateToDoFetchType> => {
        const response = await fetch(`${apiUrl}/candidate/todos`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('Response was not ok.');
        }
        const data = await response.json();

        return data;
    }

    const [todos] = createResource<CandidateToDoFetchType>(() => fetchToDoes(userId()));

    const txtConfirmTermination = getText("contractcomponent", "confirmtermination")
    const txtSignContract = getText("contractcomponent", "signcontract")
    const txtInterview = getText("todopage", "interview")
    const txtNotAcceptedInterviews = getText("todopage", "confirminterviews")
    const txtUpcommingInterviews = getText("todopage", "upcomminginterviews")
    const txtProposals = getText("todopage", "proposals")
    const txtFeedbacks = getText("todopage", "recommendations")
    const txtOnSiteInterview = getText("interviewcomponent", "onsiteinterview")
    const txtInterviewByMedia = getText("interviewcomponent", "interviewbymedia")


    const [openFilter, setOpenFilter] = createSignal(false);
    const handleOpenFilter = () => {
        alert('handleOpenFilter')
        setOpenFilter(true);
    }
    const navigate = useNavigate();

    const handleNavigateRoute = (name: string) => {
        navigate(name);
    }
 
    const prettyInterviewComment = (interviewDate: string, interviewOnSite: string, interviewType: string) => {
        if (!interviewDate)
            return "Interview not scheduled";
        return interviewOnSite === "Yes" ? txtOnSiteInterview() + " " + formatDateByCountry(interviewDate) : interviewType + " " + formatDateByCountry(interviewDate)
    }
    const prettyInterviewSchedule = (interviewDate: string, interviewOnSite: string, interviewType: string) => {
        if (!interviewDate)
            return "Not Scheduled";
        return formatDateByCountry(interviewDate) + formatTimeByCountry(interviewDate)
    }
    const prettyInterviewType = (interviewOnSite: string, interviewType: string) => {
        if (!interviewOnSite || !interviewType)
            return "";
        return interviewOnSite === "Yes" ? txtOnSiteInterview() : txtInterviewByMedia() + interviewType
    }

    return (
        <>
            <Card sx={{ margin: 1 }}>

                {/* Show the todo menu */}
                <Stack direction="row" alignItems="left" spacing={2} sx={{ margin: 1, display: 'flex' }} >
                    <PageListMenu options={customerTodoMenu} handleEvent={handleOpenFilter} />
                </Stack>
                <Divider />

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    {/* Status info for the candidate todos */}
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        {todos()?.interviewsNotAccepted?.length} <GetTextUI formName={"todopage"} label={"numbersnotconfirmedinterviews"} />.
                        {todos()?.interviewsAccepted?.length} <GetTextUI formName={"todopage"} label={"numbersupcomminginterviews"} />.
                        {todos()?.proposals?.length} <GetTextUI formName={"todopage"} label={"numbersproposal"} />.
                        {todos()?.contracts?.length} <GetTextUI formName={"todopage"} label={"numbersreadytosign"} />.
                        {todos()?.terminations?.length} <GetTextUI formName={"todopage"} label={"numberstermination"} />.
                        {todos()?.feedbacksAskFor?.length} <GetTextUI formName={"todopage"} label={"numberrecommendations"} />.
                    </Alert>
                    <Divider />

                    <Show when={!todos()?.interviewsNotAccepted?.length && !todos()?.interviewsAccepted?.length && !todos()?.proposals?.length && !todos()?.contracts?.length} >

                        <br />
                        <Alert sx={{ px: 1, py: 1 }} severity="success">
                            <GetTextUI formName={"todopage"} label={"startactions"} />.
                            <Stack direction="row" spacing={2} margin={2}>
                                <Button size="small" variant="outlined" onClick={() => handleNavigateRoute("/mycv")}><GetTextUI formName={"todopage"} label={"askforrecommendations"} /></Button>
                                <Button size="small" variant="outlined" onClick={() => handleNavigateRoute("/candidatematching")}><GetTextUI formName={"todopage"} label={"findjob"} /></Button>
                            </Stack>
                        </Alert>
                        <Divider />
                        <br />
                    </Show>
                    <Show when={todos()?.interviewsNotAccepted?.length}>
                        <CardWithMinimize iconName="interview" formName={"todopage"} formLabel={"notacceptedinterviews"} size={todos()?.interviewsNotAccepted?.length} header={txtNotAcceptedInterviews() + " (" + todos()?.interviewsNotAccepted?.length + ")" as string} type="stack" defaultOpen={false}>
                            <For each={todos()?.interviewsNotAccepted}>
                                {(item) => (
                                    <AvatarWithWfButtons
                                        avatarSize="medium"
                                        showWfItems={showWfCandidateToDoPendingInterviewsItems}
                                        avatarName={item.avatarName}
                                        interviewId={item.interviewId}
                                        jobRoleId={item.jobRoleId}
                                        jobName={item.jobName}
                                        locationCountry={item.jobCountry}
                                        locationCity={item.jobCity}
                                        jobStartDate={formatDateByCountry(item.jobStartDate)}
                                        jobEndDate={formatDateByCountry(item.jobEndDate)}
                                        workOnsite={item.workOnsite}
                                        workRemote={item.workRemote}
                                        onSiteText={item.onSiteText}
                                        interviewDate={prettyInterviewSchedule(item.interviewDate, item.interviewOnSite, item.interviewType)}
                                        interviewType={prettyInterviewType(item.interviewOnSite, item.interviewType)}
                                        highlightComment={prettyInterviewComment(item.interviewDate, item.interviewOnSite, item.interviewType)}

                                        role={item.role}
                                        src={item?.src ? item?.src : ""}
                                    />
                                )}
                            </For>
                        </CardWithMinimize>
                    </Show>
                    <Show when={todos()?.interviewsAccepted?.length}>
                        <CardWithMinimize iconName="interview" header={txtUpcommingInterviews() + " (" + todos()?.interviewsAccepted?.length + ")" as string} type="stack" defaultOpen={false}>
                            <For each={todos()?.interviewsAccepted}>
                                {(item) => (
                                    <AvatarWithWfButtons
                                        avatarSize="medium"
                                        showWfItems={showWfCandidateToDoInterviewsItems}
                                        avatarName={item.avatarName}
                                        interviewId={item.interviewId}
                                        jobRoleId={item.jobRoleId}
                                        jobName={item.jobName}
                                        jobStartDate={formatDateByCountry(item.jobStartDate)}
                                        jobEndDate={formatDateByCountry(item.jobEndDate)}
                                        workOnsite={item.workOnsite}
                                        workRemote={item.workRemote}
                                        onSiteText={item.onSiteText}
                                        interviewDate={prettyInterviewSchedule(item.interviewDate, item.interviewOnSite, item.interviewType)}
                                        interviewType={prettyInterviewType(item.interviewOnSite, item.interviewType)}
                                        highlightComment={prettyInterviewComment(item.interviewDate, item.interviewOnSite, item.interviewType)}
                                        role={item.role}
                                        src={item?.src ? item?.src : ""}
                                    />
                                )}
                            </For>
                        </CardWithMinimize>
                    </Show>
                    <Show when={todos()?.proposals?.length}>
                        <CardWithMinimize iconName="proposal" header={txtProposals() + " (" + todos()?.proposals?.length + ")" as string} type="stack" defaultOpen={false}>
                            <For each={todos()?.proposals}>
                                {(item) => (
                                    <AvatarWithWfButtons
                                        avatarSize="medium"
                                        showWfItems={showWfCandidateToDoProposalItems}
                                        avatarName={item.avatarName}
                                        proposalId={item.proposalId}
                                        jobRoleId={item.jobRoleId}
                                        jobName={item.jobName}
                                        jobStartDate={formatDateByCountry(item.jobStartDate)}
                                        jobEndDate={formatDateByCountry(item.jobEndDate)}
                                        rateFlat={item.rateFlat}
                                        rateOnsite={item.rateOnsite}
                                        rateRemote={item.rateRemote}
                                        currency={item.currency}
                                        workOnsite={item.workOnsite}
                                        workRemote={item.workRemote}
                                        onSiteText={item.onSiteText}
                                        wfStatus={item.wfStatus}
                                        role={item.role}
                                        src={item?.src ? item?.src : ""}
                                    />
                                )}
                            </For>
                        </CardWithMinimize>
                    </Show>
                    <Show when={todos()?.contracts?.length}>
                        <CardWithMinimize iconName="contract" header={txtSignContract() + " (" + todos()?.contracts?.length + ")" as string} type="stack" defaultOpen={false}>
                            <For each={todos()?.contracts}>
                                {(item) => (
                                    <AvatarWithWfButtons
                                        avatarSize="medium"
                                        showWfItems={showWfCandidateToDoContractItems}
                                        avatarName={item.avatarName}
                                        contractId={item.contractId}
                                        jobRoleId={item.jobRoleId}
                                        jobName={item.jobName}
                                        jobStartDate={formatDateByCountry(item.jobStartDate)}
                                        jobEndDate={formatDateByCountry(item.jobEndDate)}
                                        rateFlat={item.rateFlat}
                                        rateOnsite={item.rateOnsite}
                                        rateRemote={item.rateRemote}
                                        currency={item.currency}
                                        workOnsite={item.workOnsite}
                                        workRemote={item.workRemote}
                                        onSiteText={item.onSiteText}
                                        wfStatus={item.wfStatus}
                                        role={item.role}
                                        src={item?.src ? item?.src : ""}
                                    />
                                )}
                            </For>
                        </CardWithMinimize>
                    </Show>
                    <Show when={todos()?.terminations?.length}>
                        <CardWithMinimize iconName="contract" header={txtConfirmTermination() + " (" + todos()?.terminations?.length + ")" as string} type="stack" defaultOpen={false}>
                            <For each={todos()?.terminations}>
                                {(item) => (
                                    <AvatarWithWfButtons
                                        avatarSize="medium"
                                        showWfItems={showWfCandidateToDoTerminateContractItems}
                                        avatarName={item.avatarName}
                                        jobName={item.jobName}
                                        jobStartDate={formatDateByCountry(item.jobStartDate)}
                                        jobEndDate={formatDateByCountry(item.jobEndDate)}
                                        onSiteText={item.onSiteText}
                                        wfStatus={item.wfStatus}
                                        role={item.role}
                                        src={item?.src ? item?.src : ""}
                                    />
                                )}
                            </For>
                        </CardWithMinimize>
                    </Show>
                    <Show when={todos()?.feedbacksAskFor?.length}>
                        <CardWithMinimize  iconName="feedback" header={txtFeedbacks() + " (" + todos()?.feedbacksAskFor?.length + ")" as string} type="stack" defaultOpen={false}>
                            <For each={todos()?.feedbacksAskFor}>
                                {(item) => (
                                    <AvatarWithWfButtons
                                        avatarSize="medium"
                                        showWfItems={showWfCandidateRecommendationItems}
                                        avatarName={item.avatarName}
                                        jobCustomer={item.jobCustomer}
                                        jobName={item.jobName}

                                        role={item.role}
                                        src={item?.src ? item?.src : getDummyAvatar()}
                                    />
                                )}
                            </For>
                        </CardWithMinimize>
                    </Show>
                </Suspense>
            </Card>
            <br />
            <br />
        </>
    );
}

export { CandidateToDoComponent }